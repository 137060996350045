import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ handleSearch, searchBarWidth }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    handleSearch(e.target.value);
  };

  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="기호 검색..."
          value={searchTerm}
          onChange={handleChange}
          style={{ width: searchBarWidth && searchBarWidth - 40 }} // 40은 원하는 padding 값으로 조절 가능
        />
      </div>
    </div>
  );
};

export default SearchBar;
