import React from 'react';
import './SymbolGrid.css';

const padPronunciation = (pronunciation) => {
  const minLength = 6;
  if (pronunciation.length < minLength) {
    return pronunciation.padEnd(minLength, "\u00A0");
  }
  return pronunciation;
};

const SymbolGrid = ({ category, handleCopy }) => {
  return (
    <>
      {category.name === '그리스 문자' && (
        <>
          <h2 className="dovemayo-wild">그리스 문자(소문자)</h2>
          <div className="grid">
            {category.lowerSymbols.map((symbol, index) => (
              <div key={index} className="grid-item" onClick={(e) => handleCopy(symbol.symbol, e)}>
                <span>{symbol.symbol}</span>
                <hr />
                <span>{symbol.pronunciation}</span>
              </div>
            ))}
          </div>
          <h2 className="dovemayo-wild">그리스 문자(대문자)</h2>
          <div className="grid">
            {category.upperSymbols.map((symbol, index) => (
              <div key={index} className="grid-item" onClick={(e) => handleCopy(symbol.symbol, e)}>
                <span>{symbol.symbol}</span>
                <hr />
                <span>{symbol.pronunciation}</span>
              </div>
            ))}
          </div>
        </>
      )}

      {category.name !== '그리스 문자' && (
        <>
          <h2 className="dovemayo-wild">{category.name}</h2>
          <div className="grid">
            {category.symbols.map((symbol, index) => (
              <div key={index} className="grid-item" onClick={(e) => handleCopy(symbol.symbol, e)}>
                <span>{symbol.symbol}</span>
                <hr />
                <span>{symbol.pronunciation}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SymbolGrid;