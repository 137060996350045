import React from 'react';
import './Header.css';
import logo from './images/logo_trans.png';

const Header = ({ handleCategoryClick, categories }) => {
  return (
    <header className="header-container">
      <div className="logo-container">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            handleCategoryClick(categories[0]);
          }}
        >
          <img src={logo} alt="MyMathSymbol" className="logo" />
        </a>
      </div>
    </header>
  );
};

export default Header;