import React from 'react';
import './RecentSymbols.css';

const RecentSymbols = ({ recentSymbols, handleCopy, getSymbolPronunciation }) => {
  const updateSymbols = (symbols, newSymbol) => {
    const index = symbols.findIndex(item => item === newSymbol);
    if (index !== -1) {
      symbols.splice(index, 1);
    }
    symbols.unshift(newSymbol);

    // 최대 10개의 최근 사용한 기호만 저장
    while (symbols.length > 10) {
      symbols.pop();
    }
    return symbols;
  };

  const uniqueSymbols = recentSymbols.reduce((acc, symbol) => {
    if (!acc.includes(symbol)) {
      acc.push(symbol);
    } else if (acc[0] !== symbol) {
      acc = updateSymbols(acc, symbol);
    }
    return acc;
  }, []);
  
  return (
    <>
      {/* 최근 사용한 기호 영역이 비어있지 않을 때만 표시 */}
      {uniqueSymbols.length > 0 && (
        <>
          <h2 className="dovemayo-wild">최근 사용한 기호</h2>
          <div className="grid recent">
            {uniqueSymbols.map((symbol, index) => (
              <div key={index} className="grid-item" onClick={(e) => handleCopy(symbol, e)}>
                <span>{symbol}</span>
                <hr />
                <span>{getSymbolPronunciation(symbol)}</span>
              </div>
            ))}
          </div>
        </>
      )}

      {/* 최근 사용한 기호 영역이 비어있을 때 표시할 메시지 */}
      {uniqueSymbols.length === 0 && (
        <div className="empty-message dovemayo-wild">
          최근 사용한 기호가 없습니다. 기호를 클릭하여 복사해보세요!
        </div>
      )}
    </>
  );
};

export default RecentSymbols;