import React, { useEffect, useRef, useState } from 'react';
import './CategoryButtons.css';

const CategoryButtons = ({ categories, handleCategoryClick, setSearchBarWidth }) => {
  const categoriesRef = useRef(null);
  const [categoriesWidth, setCategoriesWidth] = useState(null);

  useEffect(() => {
    if (categoriesRef.current) {
      setCategoriesWidth(categoriesRef.current.clientWidth);
      setSearchBarWidth(categoriesRef.current.clientWidth);
    }
  }, [categoriesRef, setSearchBarWidth]);

  return (
    <div className="categories" ref={categoriesRef}>
      {categories.map((category, index) => (
        <button key={index} onClick={() => handleCategoryClick(category)}>
          {category.name}
        </button>
      ))}
    </div>
  );
};

export default CategoryButtons;