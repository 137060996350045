import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <p className="contact-text">mymathsymbol@gmail.com</p>
      <p className="copyright-text">ⒸMyMathSymbol</p>
    </div>
  );
};

export default Contact;