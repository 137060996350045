import React from 'react';
import './NewContainer.css';

const NewContainer = () => {
  return (
    <div className="new-container">
      <h3>사용 순서</h3>
      <ol>
        <li>원하는 수학 기호를 찾거나 검색하세요.</li>
        <li>원하는 기호 위에 마우스를 올리고 클릭하세요.</li>
        <li>클릭한 기호가 자동으로 복사되며, 이를 수학 문제나 공식 작성에 붙여넣기하세요.</li>
      </ol>
      <h3>사용 팁</h3>
      <ol>
        <li>자주 사용하는 기호를 '최근 사용한 기호'로 등록하여 편리하게 이용할 수 있습니다.</li>
        <li>검색 기능을 활용하여 원하는 수학 기호를 빠르게 찾을 수 있습니다.</li>
        <li>다양한 카테고리를 통해 필요한 기호를 손쉽게 찾아 복사할 수 있습니다.</li>
      </ol>
    </div>
  );
};

export default NewContainer;