import React, { useState } from 'react';
import './style.css';
import categories from './components/symbols';
import Header from './components/Header';
import CategoryButtons from './components/CategoryButtons';
import RecentSymbols from './components/RecentSymbols';
import CopiedMessage from './components/CopiedMessage';
import SymbolGrid from './components/SymbolGrid';
import Contact from './components/Contact';
import SearchBar from './components/SearchBar';
import NewContainer from './components/NewContainer';

const getRecentSymbols = () => {
  const storedSymbols = JSON.parse(localStorage.getItem('recentSymbols'));
  return storedSymbols || [];
};

const addRecentSymbol = (symbol) => {
  const recentSymbols = getRecentSymbols();
  recentSymbols.unshift(symbol);
  if (recentSymbols.length > 10) {
    recentSymbols.pop();
  }
  localStorage.setItem('recentSymbols', JSON.stringify(recentSymbols));
};

const getSymbolPronunciation = (symbol) => {
  for (const category of categories) {
    for (const group of ['lowerSymbols', 'upperSymbols', 'symbols']) {
      if (category[group]) {
        for (const item of category[group]) {
          if (item.symbol === symbol) {
            return item.pronunciation;
          }
        }
      }
    }
  }
  return '';
};

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [prevCategory, setPrevCategory] = useState(null);
  const [recentSymbols, setRecentSymbols] = useState(getRecentSymbols());
  const [copiedPosition, setCopiedPosition] = useState({ show: false, top: 0, left: 0 });
  const [searchBarWidth, setSearchBarWidth] = useState(null);

  const handleCategoryClick = (category) => {
    setPrevCategory(null); // 카테고리를 클릭할 때마다 prevCategory를 초기화합니다.
    setSelectedCategory(category);
    setCopiedPosition({ ...copiedPosition, show: false });
  };

  const handleCopy = (text, e) => {
    navigator.clipboard.writeText(text);
  
    const recentSymbolsList = getRecentSymbols();
    const symbolIndex = recentSymbolsList.indexOf(text);
  
    if (symbolIndex > 0) {
      const updatedSymbols = recentSymbolsList.filter((_, index) => index !== symbolIndex);
      updatedSymbols.unshift(text);
      setRecentSymbols(updatedSymbols);
      localStorage.setItem('recentSymbols', JSON.stringify(updatedSymbols));
    } else if (symbolIndex === -1) {
      addRecentSymbol(text);
      setRecentSymbols(getRecentSymbols());
    }
  
    const { top, left } = e.target.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setCopiedPosition({ show: true, top: top + scrollTop, left });
  
    setTimeout(() => setCopiedPosition({ ...copiedPosition, show: false }), 2000);
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm === "") {
      if (prevCategory) {
        setSelectedCategory(prevCategory);
        setPrevCategory(null);
      }
    } else {
      if (!prevCategory) {
        setPrevCategory(selectedCategory);
      }
  
      const matchingSymbols = categories.flatMap(category => {
        return ['lowerSymbols', 'upperSymbols', 'symbols'].flatMap(group => {
          return category[group] ? category[group].filter(item => item.pronunciation.includes(searchTerm)) : [];
        });
      });
  
      // 중복 제거를 위해 reduce 메서드 사용
      const uniqueMatchingSymbols = matchingSymbols.reduce((accumulator, current) => {
        const isDuplicate = accumulator.some(item => item.symbol === current.symbol && item.pronunciation === current.pronunciation);
        if (!isDuplicate) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
  
      if (uniqueMatchingSymbols.length > 0) {
        setSelectedCategory({
          name: "검색 기호",
          symbols: uniqueMatchingSymbols
        });
      }
    }
  };

  return (
    <div className="main-container">
      <Header handleCategoryClick={handleCategoryClick} categories={categories} />
      <SearchBar handleSearch={handleSearch} searchBarWidth={searchBarWidth} />
      <div className="container">
        <CategoryButtons
          categories={categories}
          handleCategoryClick={handleCategoryClick}
          setSearchBarWidth={setSearchBarWidth}
        />
        <RecentSymbols
          recentSymbols={recentSymbols}
          handleCopy={handleCopy}
          getSymbolPronunciation={getSymbolPronunciation}
        />
        <SymbolGrid category={selectedCategory} handleCopy={handleCopy} />
        <CopiedMessage
          show={copiedPosition.show}
          top={copiedPosition.top}
          left={copiedPosition.left}
        />
      </div>
      <NewContainer />
      <Contact />
    </div>
  );
};

export default App;