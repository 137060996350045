const categories = [
    {
      name: '그리스 문자',
      lowerSymbols: [
          { pronunciation: '알파', symbol: 'α' },
          { pronunciation: '베타', symbol: 'β' },
          { pronunciation: '감마', symbol: 'γ' },
          { pronunciation: '델타', symbol: 'δ' },
          { pronunciation: '엡실론', symbol: 'ε' },
          { pronunciation: '제타', symbol: 'ζ' },
          { pronunciation: '에타', symbol: 'η' },
          { pronunciation: '쎄타', symbol: 'θ' },
          { pronunciation: '이오타', symbol: 'ι' },
          { pronunciation: '카파', symbol: 'κ' },
          { pronunciation: '람다', symbol: 'λ' },
          { pronunciation: '뮤', symbol: 'μ' },
          { pronunciation: '누', symbol: 'ν' },
          { pronunciation: '크사이', symbol: 'ξ' },
          { pronunciation: '오미크론', symbol: 'ο' },
          { pronunciation: '파이', symbol: 'π' },
          { pronunciation: '로', symbol: 'ρ' },
          { pronunciation: '시그마', symbol: 'σ' },
          { pronunciation: '타우', symbol: 'τ' },
          { pronunciation: '유파', symbol: 'υ' },
          { pronunciation: '파이', symbol: 'φ' },
          { pronunciation: '카이', symbol: 'χ' },
          { pronunciation: '프사이', symbol: 'ψ' },
          { pronunciation: '오메가', symbol: 'ω' },
      ],
      upperSymbols: [
          { pronunciation: '알파', symbol: 'Α' },
          { pronunciation: '베타', symbol: 'Β' },
          { pronunciation: '감마', symbol: 'Γ' },
          { pronunciation: '델타', symbol: 'Δ' },
          { pronunciation: '엡실론', symbol: 'Ε' },
          { pronunciation: '제타', symbol: 'Ζ' },
          { pronunciation: '에타', symbol: 'Η' },
          { pronunciation: '쎄타', symbol: 'Θ' },
          { pronunciation: '이오타', symbol: 'Ι' },
          { pronunciation: '카파', symbol: 'Κ' },
          { pronunciation: '람다', symbol: 'Λ' },
          { pronunciation: '뮤', symbol: 'Μ' },
          { pronunciation: '누', symbol: 'Ν' },
          { pronunciation: '크사이', symbol: 'Ξ' },
          { pronunciation: '오미크론', symbol: 'Ο' },
          { pronunciation: '파이', symbol: 'Π' },
          { pronunciation: '로', symbol: 'Ρ' },
          { pronunciation: '시그마', symbol: 'Σ' },
          { pronunciation: '타우', symbol: 'Τ' },
          { pronunciation: '유파', symbol: 'Υ' },
          { pronunciation: '파이', symbol: 'Φ' },
          { pronunciation: '카이', symbol: 'Χ' },
          { pronunciation: '프사이', symbol: 'Ψ' },
          { pronunciation: '오메가', symbol: 'Ω' },
      ],
    },
    {
      name: '수학 상수 및 미적분',
      symbols: [
        { pronunciation: '적분', symbol: '∫' },
        { pronunciation: '부분미분', symbol: '∂' },
        { pronunciation: '델타', symbol: 'Δ' },
        { pronunciation: '그레디언트', symbol: '∇' },
        { pronunciation: '라플라시안', symbol: '∇²' },
        { pronunciation: '곱(파이)', symbol: 'Π' },
        { pronunciation: '곱(Disjoint Union)', symbol: '∐' },
        { pronunciation: '스칼라 곱', symbol: '⋅' },
        { pronunciation: '절대값', symbol: '|x|' },
        { pronunciation: '벡터 길이', symbol: '||x||' },
        { pronunciation: '대입', symbol: '≔' },
        { pronunciation: '파이', symbol: 'π' },
        { pronunciation: '시그마', symbol: 'Σ' },
        { pronunciation: '카이 제곱', symbol: 'χ²' },
        { pronunciation: '무한대', symbol: '∞' },
        { pronunciation: '제곱근', symbol: '√' },
        { pronunciation: '비례', symbol: '∝' },
        { pronunciation: '플러스 마이너스', symbol: '±' },
        { pronunciation: '마이너스 플러스', symbol: '∓' },
        { pronunciation: '자연수 집합', symbol: 'ℕ' },
        { pronunciation: '정수 집합', symbol: 'ℤ' },
        { pronunciation: '유리수 집합', symbol: 'ℚ' },
        { pronunciation: '실수 집합', symbol: 'ℝ' },
        { pronunciation: '이중 적분', symbol: '∬' },
        { pronunciation: '삼중 적분', symbol: '∭' },
        { pronunciation: '4중 적분', symbol: '∰' },
        { pronunciation: '폐곡선 적분', symbol: '∮' },
        { pronunciation: '면적분', symbol: '∯' },
        { pronunciation: '체적분', symbol: '∰' },
      ],
    },
    {
      name: '집합 및 논리 기호',
      symbols: [
        { pronunciation: '원소', symbol: '∈' },
        { pronunciation: '원소 미포함', symbol: '∉' },
        { pronunciation: '부분 집합', symbol: '⊆' },
        { pronunciation: '진 부분 집합', symbol: '⊂' },
        { pronunciation: '부분집합 또는 같음', symbol: '⊑' },
        { pronunciation: '상위 집합', symbol: '⊇' },
        { pronunciation: '진 상위 집합', symbol: '⊃' },
        { pronunciation: '진상위집합 또는 같음', symbol: '⊇' },
        { pronunciation: '상위집합 또는 같음', symbol: '⊒' },
        { pronunciation: '합집합', symbol: '∪' },
        { pronunciation: '교집합', symbol: '∩' },
        { pronunciation: '차집합', symbol: '∖' },
        { pronunciation: '여집합', symbol: 'A̅' },
        { pronunciation: '공집합', symbol: '∅' },
        { pronunciation: '무한 집합', symbol: '∞' },
        { pronunciation: '등호가 아님', symbol: '≠' },
        { pronunciation: '작거나 같음', symbol: '≤' },
        { pronunciation: '크거나 같음', symbol: '≥' },
        { pronunciation: '대략적으로 같음', symbol: '≈' },
        { pronunciation: '같은 형태를 가짐', symbol: '≅' },
        { pronunciation: '논리적 동치', symbol: '≡' },
        { pronunciation: '비례', symbol: '∝' },
        { pronunciation: '매우 작음', symbol: '≪' },
        { pronunciation: '매우 큼', symbol: '≫' },
        { pronunciation: '약간의 차이', symbol: '≃' },
        { pronunciation: '비슷한 형태', symbol: '≍' },
        { pronunciation: '동일한 크기', symbol: '≐' },
        { pronunciation: '같거나 작음', symbol: '⪯' },
        { pronunciation: '같거나 큼', symbol: '⪰' },
        { pronunciation: '그러므로', symbol: '∴' },
        { pronunciation: '이유는', symbol: '∵' },
        { pronunciation: '멱집합', symbol: '𝒫' },
        { pronunciation: '스칼라 곱', symbol: '⋅' },
        { pronunciation: '집합의 대칭 차집합', symbol: 'Δ' },
        { pronunciation: '모델', symbol: '⊨' },
        { pronunciation: '모델이 아님', symbol: '⊭' },
        { pronunciation: '불가', symbol: '⊥' },
        { pronunciation: '증명', symbol: '⊢' },
        { pronunciation: '증명 완료', symbol: '⊣' },
        { pronunciation: '릴레이션 조인', symbol: '⋈' },
        { pronunciation: '왼쪽 꼬리', symbol: '⊴' },
        { pronunciation: '오른쪽 꼬리', symbol: '⊵' },
      ]
    },
    {
      name: '화살표',
      symbols: [
        { pronunciation: '왼쪽 화살표', symbol: '←' },
        { pronunciation: '오른쪽 화살표', symbol: '→' },
        { pronunciation: '왼쪽 화살표', symbol: '↤' },
        { pronunciation: '오른쪽 화살표', symbol: '↦' },
        { pronunciation: '양방향 화살표', symbol: '↔' },
        { pronunciation: '단방향 더블 화살표', symbol: '⇐' },
        { pronunciation: '단방향 더블 화살표', symbol: '⇒' },
        { pronunciation: '양방향 더블 화살표', symbol: '⇔' },
        { pronunciation: '상승', symbol: '↑' },
        { pronunciation: '감소', symbol: '↓' },
        { pronunciation: '상승', symbol: '⇑' },
        { pronunciation: '하강', symbol: '⇓' },
        { pronunciation: '증가', symbol: '↑' },
        { pronunciation: '감소', symbol: '↓' },
        { pronunciation: '논리 곱', symbol: '∧' },
        { pronunciation: '논리 합', symbol: '∨' },
        { pronunciation: '합집합', symbol: '⊔' },
        { pronunciation: '교집합', symbol: '⊓' },
        { pronunciation: '집합 합', symbol: '⊎' },
        { pronunciation: '일대일 대응', symbol: '↔' },
        { pronunciation: '부분함수', symbol: '↼' },
        { pronunciation: '우전달', symbol: '⇀' },
        { pronunciation: '함수 적용', symbol: '⊸' },
        { pronunciation: '상승/하강', symbol: '↕' },
        { pronunciation: '양방향 상승/하강', symbol: '⇕' },
        { pronunciation: '왕복 화살표', symbol: '↔' },
        { pronunciation: '반시계 방향 화살표', symbol: '↺' },
        { pronunciation: '시계 방향 화살표', symbol: '↻' },
        { pronunciation: '상단 이동 화살표', symbol: '↰' },
        { pronunciation: '하단 이동 화살표', symbol: '↱' },
        { pronunciation: '상승 화살표 두 개', symbol: '⇈' },
        { pronunciation: '하강 화살표 두 개', symbol: '⇊' },
        { pronunciation: '내림함수', symbol: '⌊⌋' },
        { pronunciation: '올림함수', symbol: '⌈⌉' },
        { pronunciation: '왼쪽 화살표', symbol: '⇇' },
        { pronunciation: '오른쪽 화살표', symbol: '⇉' },
        { pronunciation: '양방향 화살표', symbol: '⇆' },
        { pronunciation: '왼쪽 화살표', symbol: '⇚' },
        { pronunciation: '오른쪽 화살표', symbol: '⇛' },
        { pronunciation: '왼쪽 더블 화살표', symbol: '↞' },
        { pronunciation: '오른쪽 더블 화살표', symbol: '↠' },
        { pronunciation: '왼쪽 화살표', symbol: '↢' },
        { pronunciation: '오른쪽 화살표', symbol: '↣' },
        { pronunciation: '왼쪽 원호 화살표', symbol: '↫' },
        { pronunciation: '오른쪽 원호 화살표', symbol: '↬' },
        { pronunciation: '왼쪽 화살표', symbol: '↶' },
        { pronunciation: '오른쪽 화살표', symbol: '↷' },
        { pronunciation: '왼쪽 화살표', symbol: '⇠' },
        { pronunciation: '오른쪽 화살표', symbol: '⇢' },
        { pronunciation: '위쪽 화살표', symbol: '↿' },
        { pronunciation: '위쪽 화살표', symbol: '↾' },
        { pronunciation: '아래쪽 화살표', symbol: '⇃' },
        { pronunciation: '아래쪽 화살표', symbol: '⇂' },
        { pronunciation: '오른쪽 화살표', symbol: '⇝' },
        { pronunciation: '왼쪽 화살표', symbol: '⇜' },
        { pronunciation: '양방향 화살표', symbol: '↭' },
        { pronunciation: '왼쪽 화살표', symbol: '↚' },
        { pronunciation: '오른쪽 화살표', symbol: '↛' },
        { pronunciation: '왼쪽 화살표', symbol: '⇍' },
        { pronunciation: '오른쪽 화살표', symbol: '⇏' },
        { pronunciation: '양쪽 방향 화살표', symbol: '↮' },
        { pronunciation: '양쪽 방향 화살표', symbol: '⇎' },
      ]
    },
    {
      name: '기타 기호',
      symbols: [
        { pronunciation: '기타', symbol: '🎸' },
        { pronunciation: '모든', symbol: '∀' },
        { pronunciation: '존재', symbol: '∃' },
        { pronunciation: '제곱근', symbol: '√' },
        { pronunciation: '무한대', symbol: '∞' },
        { pronunciation: '각도', symbol: '∠' },
        { pronunciation: '항등원', symbol: 'I' },
        { pronunciation: '원', symbol: '∘' },
        { pronunciation: '삼각형', symbol: '△' },
        { pronunciation: '삼각형', symbol: '▽' },
        { pronunciation: '참', symbol: '⊤' },
        { pronunciation: '거짓', symbol: '⊥' },
        { pronunciation: '벡터 덧셈', symbol: '⊕' },
        { pronunciation: '벡터 뺄셈', symbol: '⊖' },
        { pronunciation: '벡터 외적', symbol: '⊗' },
        { pronunciation: '절', symbol: '§' },
        { pronunciation: '확인', symbol: '✓' },
        { pronunciation: '정사각형', symbol: '□' },
        { pronunciation: '길이 단위', symbol: 'ℓ' },
        { pronunciation: '연속 함수', symbol: '℘' },
        { pronunciation: '자연음', symbol: '♮' },
        { pronunciation: '상승음', symbol: '♯' },
        { pronunciation: '왼쪽 삼각형 화살표', symbol: '◃' },
        { pronunciation: '오른쪽 삼각형 화살표', symbol: '▹' },
        { pronunciation: '직사각형 연산자', symbol: '⊞' },
        { pronunciation: '직사각형 연산자', symbol: '⊟' },
        { pronunciation: '직사각형 연산자', symbol: '⊠' },
        { pronunciation: '직사각형 연산자', symbol: '⊡' },
        { pronunciation: '중심 원', symbol: '⊛' },
        { pronunciation: '중심 원', symbol: '⊚' },
        { pronunciation: '마름모', symbol: '⋄' },
        { pronunciation: 'Planck 상수', symbol: 'ℏ' },
        { pronunciation: '역편향 전류', symbol: '℧' },
        { pronunciation: '정사영', symbol: '⊘' },
        { pronunciation: '교환', symbol: '⅁' },
        { pronunciation: '이분의 일', symbol: '∔' },
        { pronunciation: '왼쪽 반 곱셈', symbol: '⋉' },
        { pronunciation: '오른쪽 반 곱셈', symbol: '⋊' },
        { pronunciation: '왼쪽 반 합', symbol: '⋒' },
        { pronunciation: '오른쪽 반 합', symbol: '⋓' },
        { pronunciation: '왼쪽 편향', symbol: '⋋' },
        { pronunciation: '오른쪽 편향', symbol: '⋌' },
        { pronunciation: '상위 교환', symbol: '⋏' },
        { pronunciation: '하위 교환', symbol: '⋎' },
        { pronunciation: '공간 반전', symbol: 'Ⅎ' },
        { pronunciation: '수직선', symbol: '╱' },
        { pronunciation: '수직선', symbol: '╲' },
        { pronunciation: '일반 직선', symbol: '⊼' },
        { pronunciation: 'XOR 연산', symbol: '⊻' },
        { pronunciation: '집합 연산', symbol: '⩞' }
      ]
    }
  ];

export default categories;