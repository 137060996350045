import React from 'react';
import './CopiedMessage.css';

const CopiedMessage = ({ show, top, left }) => {
  if (!show) return null;

  const style = {
    top: `${top}px`,
    left: `${left}px`,
  };

  return <div className="copied-message dovemayo-wild" style={style}>복사완료!</div>;
};

export default CopiedMessage;